<template>
  <div class="check-info main-page">
    <custom-header customBack @onBack="goBack"></custom-header>
    <div class="attack-top top">视频</div>
    <div class="image-box">
      <div class="img-cover">
        <video :src="videoUrl" 
        style="object-fit:contain" ref="videoUrlRef" 
         controls autoplay loop x5-playsinline="" playsinline="" webkit-playsinline=""
          x5-video-player-type="h5" x5-video-player-fullscreen="" 
          x5-video-orientation="portraint" class="video">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div class="ai-tip">视频由AI技术生成</div>
      <div v-if="videoUrl &&!loading" class="result-img">Fake</div>
      <div class="qr-scanner" v-if="loading">
        <div class="box">
          <div class="line"></div>
        </div>
      </div>
    </div>
    <!-- 检测结果 -->
     <div class="result-panel">
      <h4>检测结果</h4>
      <div class="search-condition" >
        <div class="paltform-item item" :disabled="loading">硬件平台</div>
        <div class="unspeed-item item" :disabled="loading">未加速版</div>
        <div class="speed-item item" :disabled="loading">加速版</div>
      </div>
      <ul class="platform-inner">
        <li class="clearfix" v-for="item in choosePlatform" :key="item.id">
          <div class="paltform-item item">
            <div class="desc-info">
              <div class="sub-name sub-title">{{item.cpu}}</div>
            </div>
            <div class="desc-info">
              <div class="sub-name">{{item.gpu}}<img @click="openDialog('详细型号', item)" src="@/assets/img/deep-question.png" class="icon-question"/></div>
            </div>
          </div>
          <div class="unspeed-item item">
            <van-loading color="#1989fa" size="60" v-if="loading && (!resultData[item.id] || !resultData[item.id].normal)"/>

            <div class="desc-info" v-if="resultData[item.id]">
              <div class="fail-info" v-if="resultData[item.id].normal && !resultData[item.id].normal.success">
                <div class="error">
                  <img :src="failImg" alt="">
                  <span class="tip" v-if="resultData[item.id].normal && resultData[item.id].normal.msg">{{resultData[item.id].normal.msg}}</span>
                </div>
                <van-button class="again-btn" type="info" size="medium" @click="checkAgain(item)">重新检测</van-button>
              </div>

              <div class="success-info" v-if="resultData[item.id].normal && resultData[item.id].normal.success">
                <label>平均推理耗时</label>
                <div class="average-time">
                  <span>{{resultData[item.id].normal.avgTime}}</span>
                  ms/帧
                </div>
              </div>
            </div>
          </div>
          <!-- 加速版本 -->
          <div class="speed-item item">
            <van-loading color="#1989fa" size="60" v-if="loading && (!resultData[item.id] || !resultData[item.id].speed)"/>

            <div class="desc-info" v-if="resultData[item.id]">
              <div class="fail-info" v-if="resultData[item.id].speed && !resultData[item.id].speed.success">
                <div class="error">
                  <img :src="failImg" alt="">
                  <span class="tip" v-if="resultData[item.id].speed && resultData[item.id].speed.msg">{{resultData[item.id].speed.msg}}</span>
                </div>
                <van-button class="again-btn" type="info" size="medium" @click="checkAgain(item, 1)">重新检测</van-button>
              </div>

              <div class="success-info" v-if="resultData[item.id].speed && resultData[item.id].speed.success">
                <label>平均推理耗时</label>
                <div class="average-time">
                  <span>{{resultData[item.id].speed.avgTime}}</span>
                  ms/帧
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <van-button type="info" class="choose-btn" @click="goHomePage">返回首页</van-button>
    </div>
    <!-- 弹框 -->
    <custom-dialog :show="showDialog" @close="showDialog = false" :title="dialogTitle">
      <div v-html="dialogDesc"></div>
    </custom-dialog>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader.vue";
import CustomDialog from "@/components/CustomDialog.vue";
import { DEFENSE } from "@/remote";
// 硬件平台列表
const PLATFORM_LIST = [{
  "id": 2,
  "name": "国产服务器（DCU）",
  "cpu": "国产服务器",
  "gpu": "DCU",
  "desc": "<div>处理器：Hygon C86 7185，主频2.6GHz</br>显卡：DCU，显存16GB</br>理论峰值：13.9TFLOPS@FP32 </div>"
},{
  "id": 3,
  "name": "进口服务器（英伟达V100）",
  "cpu": "进口服务器",
  "gpu": "英伟达V100",
  "desc": "处理器：Intel(R) Xeon(R) Gold 6240，主频2.6GHz</br>显卡：NVIDIA Tesla V100-PCIE，显存32GB</br>理论峰值：14TFLOPS@FP32"
}]
// 默认选中项
const CHOOSE_PLATFORM = [2,3];

export default {
  name: "video-attack",
  components: {
    "custom-header": CustomHeader,
    "custom-dialog":CustomDialog,
    
  },
  data() {
    return {
      imgUrl: '@/assets/img/deep-question.png',
      resultData:{},
      showPicker: false,
      loading:false,
      loadingAttack:false,
      resultDataAttack:{},
      platformList: PLATFORM_LIST,

      choosePlatform: PLATFORM_LIST.filter(item => (CHOOSE_PLATFORM.slice()).includes(item.id)).map(item => item),
      showDialog: false,
      dialogDesc: '',
      dialogTitle: '详细型号',
      imgResult: {}, // 显示真伪戳
      videoUrl: "",
      realFetchNum: 0, // 正常检测请求数量
      totalFetchNum: 0,
      attackImg: 'http://saas.ruijianai.com:9311/mai/image-files/tianjin/tianjinVideo/%E6%96%B9%E6%A1%88%E4%BA%8C%E5%8E%9F%E7%85%A7%E7%89%87.png.jpg'
    };
  },
  mounted() {
    this.videoUrl = this.$route.query.video;
    this.loading = true;
    this.deepfakeResult();
  },
  methods: {
    goBack(){
      this.$router.replace({
        name: 'FakeAndAttack',
        query: {
          activeTab: 12
        }
      })
    },

    goHomePage() {
      this.$router.replace({
        name: 'Home'
      })
    },

    // 检测结果
    deepfakeResult() {
      this.totalFetchNum = 4;
      CHOOSE_PLATFORM.map(id => {
        this.deepfake({macId: id, accelerate: 0});//不加速
        this.deepfake({macId: id, accelerate: 1});//加速
      })
    },

    deepfake({macId, accelerate,isCheckAgain} = {}) {
      let key = accelerate == 1 ? 'speed' : 'normal';
      DEFENSE.deepfakeCheck({macId, accelerate}).then(res => {
        let {code, data = {}} = res || {}; 
        this.loading = true;
        setTimeout(() => {
          this.resultData = {
            ...this.resultData,
            [macId]: {
              ...this.resultData[macId],
              [key]: {
                avgTime: data.avgTime,
                success: code == 200,
              }
            }
          }
          isCheckAgain ? this.againRealFetchNum++ : this.realFetchNum++;

          if (this.realFetchNum == this.totalFetchNum && this.againRealFetchNum == this.againtTotalFetchNum) {
            this.loading = false;
            // 重置次数
            this.realFetchNum = 0;
            this.totalFetchNum = 0;
            this.againRealFetchNum = 0;
            this.againtTotalFetchNum = 0;
          }
        }, data.avgTime * 20)
      })
    },

    openDialog(dialogTitle, item) {
      this.showDialog = true;
      this.dialogTitle = dialogTitle;
      this.dialogDesc = item.desc;
    },
  },
};
</script>
<style lang="less">
  @import '../../assets/less/checkInfo';
</style>